import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'

import { Color } from 'lib/colors'
import { mediaQuery } from 'lib/screenSize'

export const rem = (size: number) => `${size / 16}rem`

const textVariants = {
  h1: {
    fontFamily: 'avenir-heavy',
    fontSize: rem(56),
    lineHeight: rem(64),
    letterSpacing: rem(5.5),
    textTransform: 'uppercase',
  },
  h2: {
    fontFamily: 'avenir-black',
    fontSize: rem(45),
    lineHeight: rem(61),
    letterSpacing: rem(4.5),
    textTransform: 'uppercase',
  },
  h3: {
    fontFamily: 'avenir-black',
    fontSize: rem(35),
    lineHeight: rem(48),
    letterSpacing: rem(3.5),
    textTransform: 'uppercase',
  },
  h4: {
    fontFamily: 'avenir-black',
    fontSize: rem(24),
    lineHeight: rem(33),
    textTransform: 'uppercase',
  },
  h5: {
    fontFamily: 'avenir-heavy',
    fontSize: rem(24),
    lineHeight: rem(33),
    letterSpacing: rem(2.4),
    textTransform: 'uppercase',
  },
  h6: {
    fontFamily: 'avenir-black',
    fontSize: rem(18),
    lineHeight: rem(25),
    letterSpacing: rem(0.9),
    textTransform: 'uppercase',
  },
  alt1: {
    fontFamily: 'avenir-medium-oblique',
    fontSize: rem(35),
    lineHeight: rem(48),
    textTransform: 'uppercase',
  },
  alt2: {
    fontFamily: 'avenir-heavy-oblique',
    fontSize: rem(24),
    lineHeight: rem(33),
    textTransform: 'uppercase',
  },
  text1: {
    fontFamily: 'avenir-black',
    fontSize: rem(35),
    lineHeight: rem(48),
    letterSpacing: rem(3.5),
  },
  text2: {
    fontFamily: 'avenir-black',
    fontSize: rem(28),
    lineHeight: rem(48),
    letterSpacing: rem(2.8),
  },
  text3: {
    fontFamily: 'avenir-heavy',
    fontSize: rem(24),
    lineHeight: rem(35),
    letterSpacing: rem(2.4),
    [mediaQuery.sm]: {
      fontSize: rem(26),
    },
  },
  text35: {
    fontFamily: 'avenir-medium',
    fontSize: rem(24),
    lineHeight: rem(42),
    [mediaQuery.sm]: {
      lineHeight: rem(32),
    },
  },
  text4: {
    fontFamily: 'avenir-book',
    fontSize: rem(20),
    lineHeight: rem(27),
    letterSpacing: rem(1),
  },
}

const textColors = {
  [Color.green]: {
    color: Color.green,
  },
  [Color.red]: {
    color: Color.red,
  },
  [Color.yellow]: {
    color: Color.yellow,
  },
  [Color.white]: {
    color: Color.white,
  },
}

export type TextVariant = keyof typeof textVariants
export type TextColor = keyof typeof textColors

const useStyles = createUseStyles({
  ...textVariants,
  ...textColors,
  breakAllWords: {
    wordSpacing: '99999px',
  },
  raised: {
    textShadow: '0px 3px 6px #00000029',
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  extra: (props: TextProps) => props,
})

export type TextProps = React.PropsWithChildren<
  Omit<CSSProperties, 'color'> & {
    className?: string
    as?: keyof JSX.IntrinsicElements
    align?: 'left' | 'center' | 'right'
    variant?: TextVariant
    color?: TextColor
    size?: number
    transform?: 'lowercase' | 'uppercase'
    raised?: boolean
    breakAllWords?: boolean
  }
>

export default React.forwardRef<HTMLDivElement, TextProps>(function Text(
  {
    children,
    color = Color.white,
    variant = 'text1',
    align,
    raised = false,
    as,
    className,
    breakAllWords,
    ...props
  },
  ref,
) {
  const styles = useStyles(props)
  const classes = classNames(
    className,
    { [styles.raised]: raised, [styles.breakAllWords]: breakAllWords },
    styles[color],
    styles[variant],
    styles[align],
    styles.extra,
  )
  const Element: any = as ?? (variant.charAt(0) === 'h' ? variant : 'p')

  return (
    <Element ref={ref} className={classes}>
      {children}
    </Element>
  )
})
