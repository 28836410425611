import React from 'react'
import { createUseStyles } from 'react-jss'

import Link from 'components/Link'
import Text from 'components/Text'
import { Color } from 'lib/colors'
import { Route } from 'lib/navigation'
import { useIsSmallScreen } from 'lib/screenSize'

interface HeaderLinkProps {
  to: Route
  onClick?: () => void
  vertical?: boolean
}

const useStyles = createUseStyles({
  linkVertical: {
    margin: '48px 0',
  },
  linkHorizontal: {
    margin: '0 16px',
  },
})

export default function HeaderLink({
  onClick,
  to,
  children,
  vertical,
}: React.PropsWithChildren<HeaderLinkProps>) {
  const styles = useStyles()
  const isSmall = useIsSmallScreen()

  return (
    <Text
      variant={isSmall ? 'h2' : 'h6'}
      color={Color.green}
      align="center"
      className={vertical ? styles.linkVertical : styles.linkHorizontal}
    >
      <Link onClick={onClick} to={to}>
        {children}
      </Link>
    </Text>
  )
}
