exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-how-to-elope-in-colorado-tsx": () => import("./../../../src/pages/how-to-elope-in-colorado.tsx" /* webpackChunkName: "component---src-pages-how-to-elope-in-colorado-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-packages-tsx": () => import("./../../../src/pages/packages.tsx" /* webpackChunkName: "component---src-pages-packages-tsx" */),
  "component---src-pages-recent-adventures-tsx": () => import("./../../../src/pages/recent-adventures.tsx" /* webpackChunkName: "component---src-pages-recent-adventures-tsx" */),
  "component---src-templates-wp-post-tsx": () => import("./../../../src/templates/WpPost.tsx" /* webpackChunkName: "component---src-templates-wp-post-tsx" */)
}

