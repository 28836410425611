import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Link from 'components/Link'
import { Route } from 'lib/navigation'

interface ContactButtonProps {
  onClick?: () => void
}

export default React.memo(function ContactButton({
  onClick,
}: ContactButtonProps) {
  return (
    <Link to={Route.Contact} onClick={onClick}>
      <StaticImage
        layout="fullWidth"
        placeholder="none"
        src="../images/contact.png"
        alt="contact"
        loading="eager"
      />
    </Link>
  )
})
