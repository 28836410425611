import jss from 'jss'
jss.setup(preset())

import classNames from 'classnames'
import { PageProps } from 'gatsby'
import preset from 'jss-preset-default'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { ParallaxProvider } from 'react-scroll-parallax'

import '../fonts/font.css'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { shadowGlobalStyle } from 'lib/boxShadow'
import { Color } from 'lib/colors'
import { mediaQuery, ScreenSizeProvider } from 'lib/screenSize'

const useStyles = createUseStyles({
  '@global': {
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      overflowX: 'hidden',
      fontSize: 16,
      boxSizing: 'border-box',
      fontFamily: 'avenir-black',
      [mediaQuery.sm]: {
        fontSize: 12,
      },
    },
    '*::before, *::after': {
      boxSizing: 'inherit',
    },
    body: {
      overflowX: 'hidden',
      backgroundColor: Color.white,
      color: Color.black,
      margin: 0,
      padding: 0,
    },
    'h1, h2, h3, h4, h5, h6': {
      margin: 0,
    },
    '.tl-edges': {
      overflow: 'hidden',
    },
    p: {
      margin: 0,
    },
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
    ...shadowGlobalStyle,
  },
  page: {
    margin: 0,
    padding: 0,
    background: Color.white,
    transition: 'opacity 250ms',
    opacity: 1,
  },
  pageSSR: {
    opacity: 0,
  },
})

export default function Layout({
  children,
}: React.PropsWithChildren<PageProps>) {
  const [hasMounted, setHasMounted] = React.useState(false)
  const styles = useStyles()
  const classes = classNames(styles.page, {
    [styles.pageSSR]: !hasMounted,
  })

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  return (
    <>
      <ScreenSizeProvider>
        <ParallaxProvider>
          <div className={classes}>
            <Header />
            <main>{children}</main>
            <Footer />
          </div>
        </ParallaxProvider>
      </ScreenSizeProvider>
    </>
  )
}
