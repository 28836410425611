import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Box from 'components/Box'
import ContactButton from 'components/ContactButton'
import HeaderLink from 'components/Header/HeaderLink'
import HeaderMobileMenu from 'components/Header/HeaderMobileMenu'
import Link from 'components/Link'
import MainLinks from 'components/MainLinks'
import MenuIcon from 'components/MenuIcon'
import { Color } from 'lib/colors'
import { Route } from 'lib/navigation'
import { mediaQuery, useIsSmallScreen } from 'lib/screenSize'

export function useHeaderHeight() {
  const isSmall = useIsSmallScreen()

  return isSmall ? 72 : 94
}

const useStyles = createUseStyles({
  header: {
    position: 'relative',
    zIndex: 100,
    height: 94,
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: Color.white,
    [mediaQuery.sm]: {
      height: 72,
      padding: '0 8px 0 8px',
    },
  },
  linkContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  image: {
    width: 215,
    [mediaQuery.sm]: {
      width: 160,
    },
  },

  desktopLinks: {
    display: 'flex',
    flexDirection: 'row',
    [mediaQuery.sm]: {
      display: 'none',
    },
  },
  contactLink: {
    width: 150,
    [mediaQuery.sm]: {
      width: 110,
    },
  },
})

export default React.memo(function Header() {
  const styles = useStyles()
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const onMenuOpen = React.useCallback(() => {
    setMenuIsOpen(true)
  }, [])

  const onMenuClose = React.useCallback(() => {
    setMenuIsOpen(false)
  }, [])

  const renderLink = React.useCallback(
    (route, name) => (
      <HeaderLink key={route} to={route}>
        {name}
      </HeaderLink>
    ),
    [],
  )

  return (
    <>
      <header className={styles.header}>
        <Link to={Route.Home}>
          <Box className={styles.image}>
            <StaticImage
              layout="fullWidth"
              src="../../images/logo-green.png"
              alt="rachel beckwith photography"
              placeholder="none"
              loading="eager"
              quality={100}
            />
          </Box>
        </Link>
        <div className={styles.linkContainer}>
          <MainLinks className={styles.desktopLinks} renderLink={renderLink} />
          <Box className={styles.contactLink}>
            <ContactButton />
          </Box>
          <MenuIcon onClick={onMenuOpen} />
        </div>
      </header>
      <HeaderMobileMenu onClose={onMenuClose} isOpen={menuIsOpen} />
    </>
  )
})
