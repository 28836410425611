export enum Route {
  Home = '/',
  RecentAdventures = '/recent-adventures',
  PlanningResources = '/how-to-elope-in-colorado',
  About = '/about',
  Packages = '/packages',
  Contact = '/contact',
}

export enum ExternalLink {
  Facebook = 'https://www.facebook.com/RachelBeckwithPhotography',
  Instagram = 'https://www.instagram.com/rachbeckwithphoto',
  Pictime = 'https://rachelbeckwithphotography.pic-time.com/client',
  Pinterest = 'https://www.pinterest.com/0noye1otxxdxnsg9qzxno57qi83us7',
}

export const routes = [
  {
    path: Route.Home,
    label: 'home',
    pageTitle: 'Home',
    isSiteNavigation: false,
    keyword: 'Colorado Elopement Photographer',
  },
  {
    path: Route.PlanningResources,
    pageTitle: 'How To Elope In Colorado',
    label: 'planning resources',
    isSiteNavigation: true,
    keyword: 'How To Elope In Colorado',
  },
  {
    path: Route.About,
    pageTitle: 'About',
    label: 'about',
    isSiteNavigation: true,
    keyword: 'Adventure Elopement Photographer',
  },
  {
    path: Route.Packages,
    pageTitle: 'Packages',
    label: 'packages',
    isSiteNavigation: true,
    keyword: 'Colorado Elopement Packages',
  },
  {
    path: Route.RecentAdventures,
    pageTitle: 'Recent Adventures',
    label: 'Recent Adventures',
    isSiteNavigation: true,
    keyword: 'Recent Adventures',
  },
  {
    path: Route.Contact,
    pageTitle: 'Contact',
    label: 'contact',
    isSiteNavigation: false,
    keyword: 'Contact Colorado Elopement Photographer',
  },
]
