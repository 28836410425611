import React from 'react'
import { createUseStyles } from 'react-jss'

import { Color } from 'lib/colors'
import { mediaQuery } from 'lib/screenSize'

interface CloseIconProps {
  onClick: () => void
}

const useStyles = createUseStyles({
  menu: {
    cursor: 'pointer',
    marginLeft: 16,
    width: 28,
    '& div': {
      borderRadius: 4,
      height: 4,
      backgroundColor: Color.green,
      '&:nth-child(2)': {
        margin: '4px 0',
      },
    },
    [mediaQuery.mdUp]: {
      display: 'none',
    },
  },
})

export default React.memo(function CloseIcon({ onClick }: CloseIconProps) {
  const styles = useStyles()

  return (
    <div onClick={onClick} className={styles.menu}>
      <div />
      <div />
      <div />
    </div>
  )
})
