export const shadowStyle = {
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  borderRadius: 2,
  overflow: 'hidden',
}

export const shadowClass = 'box-shadow'

export const shadowGlobalStyle = {
  [`.${shadowClass}`]: shadowStyle,
}
