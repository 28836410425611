import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Box from 'components/Box'
import Center from 'components/Center'
import CloseIcon from 'components/CloseIcon'
import ContactButton from 'components/ContactButton'
import HeaderLink from 'components/Header/HeaderLink'
import Intersperse from 'components/Intersperse'
import MainLinks from 'components/MainLinks'
import { Color } from 'lib/colors'
import { mediaQuery } from 'lib/screenSize'

const useHeaderMenuStyles = createUseStyles({
  linkMenu: {
    position: 'fixed',
    zIndex: 999,
    backgroundColor: Color.white,
    right: '-100vw',
    width: '100vw',
    height: '100vh',
    top: 0,
    textAlign: 'center',
    transition: 'right 300ms ease-in-out',
    visibility: 'hidden',
    [mediaQuery.sm]: {
      visibility: 'visible',
    },
  },
  linkMenuOpen: { right: 0 },
  greenBg: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
    top: 24,
    cursor: 'pointer',
  },
})

interface HeaderMobileMenuProps {
  isOpen: boolean
  onClose: () => void
}

export default React.memo(function HeaderMobileMenu({
  isOpen,
  onClose,
}: HeaderMobileMenuProps) {
  const styles = useHeaderMenuStyles()
  const classes = classNames(styles.linkMenu, {
    [styles.linkMenuOpen]: isOpen,
  })
  const renderLink = React.useCallback(
    (route, name) => (
      <HeaderLink onClick={onClose} key={route} vertical to={route}>
        <Intersperse delimiter={<br />}>{name.split(' ')}</Intersperse>
      </HeaderLink>
    ),
    [onClose],
  )

  return (
    <div className={classes}>
      <div className={styles.closeIcon} onClick={onClose}>
        <CloseIcon />
      </div>
      <MainLinks renderLink={renderLink} />
      <Center width={200}>
        <ContactButton onClick={onClose} />
      </Center>
      <Box className={styles.greenBg}>
        <StaticImage
          layout="fullWidth"
          src="../../images/home/green-top.png"
          alt="green top"
        />
      </Box>
    </div>
  )
})
