import React from 'react'

interface IntersperseProps {
  delimiter: any
}

export default function Intersperse({
  delimiter,
  children,
}: React.PropsWithChildren<IntersperseProps>) {
  const childrenLength = React.Children.count(children)

  const mappedChildren = React.Children.map(children, (child, index) => {
    if (index === childrenLength - 1) return child

    return (
      <React.Fragment key={index}>
        {child}
        {delimiter}
      </React.Fragment>
    )
  })

  return <>{mappedChildren}</>
}
