import AniLink from 'gatsby-plugin-transition-link/AniLink'
import React from 'react'

export interface LinkProps {
  to: string
  title?: string
  isExternal?: boolean
  onClick?: () => void
}

export default function Link({
  children,
  isExternal,
  onClick,
  to,
  ...props
}: React.PropsWithChildren<LinkProps>) {
  if (isExternal) {
    return (
      <a {...props} target="blank" href={to} onClick={onClick}>
        {children}
      </a>
    )
  }

  return (
    <AniLink {...props} fade to={to} onClick={onClick}>
      {children}
    </AniLink>
  )
}
