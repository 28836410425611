import React from 'react'

import Intersperse from 'components/Intersperse'
import { routes } from 'lib/navigation'

interface MainLinksProps {
  renderLink(route: string, name: string): JSX.Element
  delimiter?: any
  className?: string
  excludeNavWrapper?: boolean
}

export default React.memo(function MainLinks({
  renderLink,
  excludeNavWrapper,
  delimiter,
  className,
}: MainLinksProps) {
  const links = routes.filter(({ isSiteNavigation }) => isSiteNavigation)
  const renderedLinks = links.map(({ path, label }) => renderLink(path, label))
  const content = delimiter ? (
    <Intersperse delimiter={delimiter}>{renderedLinks}</Intersperse>
  ) : (
    renderedLinks
  )

  if (excludeNavWrapper) return <>{content}</>

  return <nav className={className}>{content}</nav>
})
