export enum Color {
  grey1 = '#1a1a1a',
  grey2 = '#2a2a2a',
  grey3 = '#3b3b3b',
  grey4 = '#4b4b4b',
  grey5 = '#5c5c5c',
  grey6 = '#6c6c6c',
  grey7 = '#7d7d7d',
  grey8 = '#8d8d8d',
  grey9 = '#9e9e9e',
  greyA = '#aaaaaa',
  greyB = '#bababa',
  greyC = '#cacaca',
  greyD = '#dadada',
  greyE = '#eeeeee',
  greyF = '#fafafa',
  black = '#101214',

  dark = '#374046',
  darkBg = '#ebedef',
  error = '#ff211d',
  light = '#8697a3',
  lightBg = '#f8f9fa',
  primary = '#0498ff',
  success = '#009865',
  warning = '#ffbe1e',
  transparent = 'transparent',
  overlay = 'rgba(0, 0, 0, 0.7)',

  // Brand Colors
  green = '#0a331c',
  red = '#a34e2c',
  yellow = '#e09e1b',
  white = '#f4efe6',
}
