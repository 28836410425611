import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { createUseStyles } from 'react-jss'

import { shadowStyle } from 'lib/boxShadow'
import { ClassName } from 'lib/types'

export type BoxProps = React.PropsWithChildren<
  CSSProperties &
    ClassName & {
      as?: JSX.IntrinsicElements
      elevated?: boolean
    }
>

const useStyles = createUseStyles({
  root: (styleProps: BoxProps) => styleProps,
  elevated: shadowStyle,
})

export default React.forwardRef<HTMLDivElement, BoxProps>(function Box(
  { as = 'div', children, className, elevated, ...styleProps },
  ref,
) {
  const styles = useStyles(styleProps)
  const classes = classNames(styles.root, className, {
    [styles.elevated]: elevated,
  })

  const Element = as as any

  return (
    <Element ref={ref} className={classes}>
      {children}
    </Element>
  )
})
