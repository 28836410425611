import React from 'react'

export enum ScreenSizeBreakpoint {
  sm = 0,
  md = 960,
  lg = 1280,
}

type ScreenSize = keyof typeof ScreenSizeBreakpoint | null

const ScreenSizeContext = React.createContext<ScreenSize>('md')
export const isSSR = () => typeof window === 'undefined'

export const getWindowHeight = () => (isSSR() ? 800 : window.innerHeight)

const getSizeFromWindowWidth = () => {
  if (isSSR()) return null

  if (window.innerWidth < ScreenSizeBreakpoint.md) return 'sm'
  if (window.innerWidth < ScreenSizeBreakpoint.lg) return 'md'

  return 'lg'
}

export function ScreenSizeProvider({ children }: React.PropsWithChildren) {
  const [screenSize, setScreenSize] = React.useState<ScreenSize>(
    getSizeFromWindowWidth,
  )

  React.useEffect(() => {
    const handleResize = () => {
      const newSize = getSizeFromWindowWidth()

      if (newSize !== screenSize) {
        setScreenSize(newSize)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [screenSize])

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  )
}

export const useScreenSize = () => React.useContext(ScreenSizeContext)
export const useScreenSizeIs = (size: ScreenSize) =>
  React.useContext(ScreenSizeContext) === size
export const useIsSmallScreen = () => useScreenSizeIs('sm')

type ScreenSizeConditionParam = {
  [K in ScreenSize]?: any
}
export const useScreenSizeCondition = ({
  sm,
  md,
  lg,
}: ScreenSizeConditionParam) => {
  const screenSize = useScreenSize()

  if (screenSize === 'sm') return sm
  if (screenSize === 'md') return md

  return lg
}

const mdDownQuery = `(max-width: ${ScreenSizeBreakpoint.lg - 1}px)`
const mdUpQuery = `(min-width: ${ScreenSizeBreakpoint.md}px)`

export const rawMediaQuery = {
  sm: `(max-width: ${ScreenSizeBreakpoint.md - 1}px)`,
  mdDown: mdDownQuery,
  md: `${mdDownQuery} and ${mdUpQuery}`,
  mdUp: mdUpQuery,
  lg: `(min-width: ${ScreenSizeBreakpoint.lg}px)`,
}

export const mediaQuery = {
  sm: `@media ${rawMediaQuery.sm}`,
  mdDown: `@media ${rawMediaQuery.mdDown}`,
  md: `@media ${rawMediaQuery.md}`,
  mdUp: `@media ${rawMediaQuery.mdUp}`,
  lg: `@media ${rawMediaQuery.lg}`,
}
