import Grid from '@material-ui/core/Grid'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Box from 'components/Box'
import ContactButton from 'components/ContactButton'
import Intersperse from 'components/Intersperse'
import Link, { LinkProps } from 'components/Link'
import MainLinks from 'components/MainLinks'
import Text, { TextProps } from 'components/Text'
import { Color } from 'lib/colors'
import { ExternalLink } from 'lib/navigation'
import { useIsSmallScreen } from 'lib/screenSize'

function FooterLinkGrid({ children }: React.PropsWithChildren) {
  return (
    <Grid item xs={12} md="auto">
      {children}
    </Grid>
  )
}

function FooterLink({
  to,
  isExternal,
  variant = 'h3',
  ...props
}: TextProps & Partial<LinkProps>) {
  const text = (
    <Text
      color={Color.yellow}
      fontFamily="avenir-book"
      variant={variant}
      {...props}
    />
  )

  if (to) {
    return (
      <FooterLinkGrid>
        <Link to={to} isExternal={isExternal}>
          {text}
        </Link>
      </FooterLinkGrid>
    )
  }

  return <FooterLinkGrid>{text}</FooterLinkGrid>
}

function SocialLinks() {
  const isSmall = useIsSmallScreen()

  if (isSmall) return null

  return (
    <nav>
      <Grid container spacing={isSmall ? 1 : 2}>
        <Intersperse delimiter={isSmall ? null : <FooterLink>|</FooterLink>}>
          <FooterLink isExternal to={ExternalLink.Pinterest}>
            pinterest
          </FooterLink>
          <FooterLink isExternal to={ExternalLink.Facebook}>
            facebook
          </FooterLink>
          <FooterLink isExternal to={ExternalLink.Instagram}>
            instagram
          </FooterLink>
        </Intersperse>
      </Grid>
    </nav>
  )
}

const useStyles = createUseStyles({
  footerContainer: {
    zIndex: 1,
    position: 'relative',
    background:
      'linear-gradient(180deg, rgba(244,239,230,1) 0%, rgba(244,239,230,0) 100%)',
  },
})

function FooterLinks() {
  const isSmall = useIsSmallScreen()
  const renderLink = React.useCallback(
    (route, name) => (
      <FooterLink key={route} to={route} variant="h4">
        {name}
      </FooterLink>
    ),
    [],
  )

  const linkDelimiter = isSmall ? null : <FooterLink variant="h4">|</FooterLink>

  return (
    <MainLinks
      excludeNavWrapper
      renderLink={renderLink}
      delimiter={linkDelimiter}
    />
  )
}

export default React.memo(function Footer() {
  const isSmall = useIsSmallScreen()
  const styles = useStyles()
  const padding = isSmall ? '0 16px 16px 16px' : '0 32px 32px 32px'

  return (
    <footer className={styles.footerContainer}>
      <Box position="relative" top={16}>
        <object
          width={isSmall ? '200%' : '100%'}
          data={require('images/treeline.svg').default}
          type="image/svg+xml"
        />
      </Box>
      <Box backgroundColor={Color.green} padding={padding}>
        <Grid container justifyContent="space-between">
          <Grid item xs={7} sm={9}>
            <Box width={isSmall ? '90%' : '66%'}>
              <StaticImage
                layout="fullWidth"
                src="../images/adventure-buddies.png"
                alt="let's be adventure buddies"
                placeholder="none"
                quality={100}
              />
            </Box>
            <Box paddingTop={isSmall ? 24 : 48}>
              <SocialLinks />
              <nav>
                <Grid container spacing={isSmall ? 1 : 2}>
                  <FooterLinks />
                </Grid>
              </nav>
              <Box paddingTop={16} maxWidth={isSmall ? 120 : 180}>
                <ContactButton />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={5}
            sm={3}
            alignItems="flex-end"
            justifyContent="flex-end"
            container
          >
            <Box width="100%">
              <StaticImage
                layout="fullWidth"
                src="../images/logo-cream.png"
                alt="rachel beckwith photography"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </footer>
  )
})
